import { render, staticRenderFns } from "./NotificationPanel.vue?vue&type=template&id=4dd4b934&"
import script from "./NotificationPanel.vue?vue&type=script&lang=js&"
export * from "./NotificationPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/chatwoot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dd4b934')) {
      api.createRecord('4dd4b934', component.options)
    } else {
      api.reload('4dd4b934', component.options)
    }
    module.hot.accept("./NotificationPanel.vue?vue&type=template&id=4dd4b934&", function () {
      api.rerender('4dd4b934', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/notifications/components/NotificationPanel.vue"
export default component.exports